import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import BoldTitle from '../../components/BoldTitle';
import { CommonContext } from '../../context';
import ContractForm from '../../components/ContractForm';

export const query = graphql`
  query {
    allContact {
      nodes {
        name {
          label
          name
          required
          type
          placeholder
        }
        email {
          label
          name
          required
          type
          placeholder
        }
        subject {
          id
          label
          name
          placeholder
          options {
            label
            value
          }
          required
        }
        message {
          label
          name
          required
          type
          placeholder
        }
      }
    }
  }
`;

function ContactPage(props) {
  const data = useStaticQuery(query);
  const formInputFields = data.allContact.nodes[0];
  const [formData, setFormData] = useState({});

  const onSuccessResponse = () => {
    window.location = '/contact/success';
  };

  return (
    <CommonContext.Provider value={{ location: props.location }}>
      <Layout
        title="Contact"
        keywords={['contact', 'greentech', 'gtaf']}
        location={props.location}
      >
        <div className="flex justify-center w-full bg-neutral-1">
          <div className="w-full max-w-3xl p-6 m-6 shadow-2xl md:m-16 bg-neutral-0 rounded-2xl md:p-10">
            <section>
              <BoldTitle className="mb-6">Contact GTAF</BoldTitle>
              <ContractForm
                formData={formData}
                setFormData={setFormData}
                formInputFields={formInputFields}
                onSuccessResponse={onSuccessResponse}
              />
            </section>
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

ContactPage.propTypes = {
  location: PropTypes.object,
};

export default ContactPage;
